import '../../styles/pages/spring-summer-2021/campaign-progress.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import MoreStories from "../../components/spring-summer-2021-stories/more-stories";
import HorizontalScroll from 'react-scroll-horizontal'
import Icon from '../../components/icons'
import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';

import socialBanner from "../../images/spring-summer-2021/social-banners/campaign-progress.jpg";

import launchGraph from '../../images/spring-summer-2021/campaign-update/launch-graph.png';
import anniversaryGraph from '../../images/spring-summer-2021/campaign-update/anniversary-graph.png';
import campaignLogo from '../../images/spring-summer-2021/campaign-logo.svg';

import { isMobile } from "react-device-detect";


import ScrollIcon from '../../images/icons/scroll-icon.svg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
    if (this.state.width < 768) {
      this.setState({ isMobile: true });
    }
  };

  render() {
    var pageTitle = 'Highlights From the First Year of ‘It Takes a Titan’';
    var pageDesc = 'From notable gifts to record-breaking days of giving, Cal State Fullerton celebrates the campaign’s momentum.';
    var slug = 'campaign-progress';

    const stories = [
      {
        date: 'February 2020',
        amount: '',
        donor: 'Public launch',
        title: 'University publicly launches campaign',
        link: null,
        image: launchGraph,
        imageAlt: 'Pie chart showing 63% progress $200K goal'
      },
      {
        date: 'February 2020',
        amount: '$25,000 + 40% of estate',
        donor: 'Marc Dickey',
        title: 'Marc Dickey, professor of music: $25,000 + 40% of his estate for School of Music scholarships',
        link: 'http://news.fullerton.edu/2021/03/music-professor-tops-noteworthy-career-with-a-gift-for-future-teachers/',
        image: 'http://news.fullerton.edu/app/uploads/2021/03/Marc-Dickey-S-800x500.jpg',
        imageAlt: 'Marc Dickey, professor of music'
      },
      {
        date: 'February 2020',
        amount: '$10 million',
        donor: 'Begoviches',
        title: 'Nicholas and Lee Begovich: $10 million for gravitational-wave, engineering, computer science research',
        link: 'http://news.fullerton.edu/2020/02/campaign-gift-begovich/',
        image: 'http://news.fullerton.edu/app/uploads/2020/11/GWPAC-gift-group2.jpg',
        imageAlt: 'Joshua Smith, Fram Virjee, Lee Begovich, Geoffrey Lovelace, Nicholas Begovich, Jocelyn Read'
      },
      {
        date: 'March 2020',
        amount: '$239,139',
        donor: '#TitansGive day of giving',
        title: '#TitansGive day of giving: $239,139 raised in 24 hours',
        link: 'http://news.fullerton.edu/2020/03/day-of-giving-csuf/',
        image: 'http://news.fullerton.edu/app/uploads/2020/11/tuffy-banner.gif',
        imageAlt: 'Tuffy running though "#TitansGive" banner'
      },
      {
        date: 'June 2020',
        amount: '$400,000',
        donor: 'Hodges Fellowship Endowment',
        title: 'Hodges Fellowship Endowment: $400,000 to support students in English or comparative literature',
        link: 'http://news.fullerton.edu/2020/06/robert-r-hodges-fellowship-endowment/',
        image: 'http://news.fullerton.edu/app/uploads/2020/11/Robert-Hodges2.jpg',
        imageAlt: 'Robert R. Hodges, professor emeritus of English'
      },
      {
        date: 'October 2020',
        amount: '$415,000',
        donor: 'Titan Emergency and Basic Needs Network',
        title: 'Titan Emergency and Basic Needs Network: $415,000 for student basic needs',
        link: null
      },
      {
        date: 'October 2020',
        amount: '$100,000',
        donor: 'Mysun Foundation',
        title: 'Mysun Foundation: $100,000 to help maintain the arboretum grounds and plants',
        link: 'http://news.fullerton.edu/2021/02/fullerton-arboretum-plants-survive-and-thrive-thanks-to-100000-matching-grant/',
        image: 'http://news.fullerton.edu/app/uploads/2021/02/Arboretum-Spring-800x500.jpg',
        imageAlt: 'Fullerton Arboretum trees in spring'
      },
      {
        date: 'November 2020 ',
        amount: '$300,000',
        donor: 'Tarsadia Foundation',
        title: 'Tarsadia Foundation:  $300,000 for the Center for Healthy Neighborhoods',
        link: 'http://news.fullerton.edu/2021/02/tarsadia-foundation-gifts-300000-to-center-for-healthy-neighborhoods/',
        image: 'http://news.fullerton.edu/app/uploads/2021/02/Center-Healthy-Neighborhoods-800x500.jpg',
        imageAlt: 'Center for Healthy Neighborhoods mentor an mentee'
      },
      {
        date: 'December 2020',
        amount: '$45,000',
        donor: 'Alumni Association',
        title: 'Alumni Association: $45,000 for students in need due to the pandemic',
        link: 'http://news.fullerton.edu/2021/01/alumni-association-steps-up-with-45000-grant-to-help-current-students/',
        image: 'http://news.fullerton.edu/app/uploads/2021/01/Spring_2020_010-800x500.jpg',
        imageAlt: 'Alumni House, home to the CSUF Alumni Association'
      },
      {
        date: 'December 2020',
        amount: '$1 million',
        donor: 'Terry Giles',
        title: 'Terry Giles ’70, owner and president of Giles Enterprises: $1 million to the Center for Leadership',
        link: 'http://news.fullerton.edu/2021/02/alumnus-commits-1-million-gift-to-center-for-leadership/',
        image: 'http://news.fullerton.edu/app/uploads/2021/02/Terry-Giles-Marina-800x500.jpg',
        imageAlt: 'Terry Giles'
      },
      {
        date: 'January 2021',
        amount: '$500,000',
        donor: 'NBCUniversal',
        title: 'NBCUniversal: $500,000 to advance training of diverse journalists',
        link: 'http://news.fullerton.edu/2021/01/500000-grant-from-nbcuniversal-to-advance-training-of-diverse-journalists/',
        image: 'http://news.fullerton.edu/app/uploads/2021/01/NBC-Grant-800x500.jpg',
        imageAlt: 'Broadcast students learn on set.'
      },
      {
        date: 'February 2021',
        amount: '$800,000',
        donor: 'SchoolsFirst',
        title: 'SchoolsFirst: $800,000 to support future teachers',
        link: 'http://news.fullerton.edu/2021/03/schoolsfirst-fcu-donates-800000-to-enhance-support-for-teachers/',
        image: 'http://news.fullerton.edu/app/uploads/2021/02/CSUF-College-Education-Grant-Schools-First-800x500.jpg',
        imageAlt: 'CSUF College of Education'
      },
      {
        date: 'February  2021',
        amount: '$75,000',
        donor: 'The Cervantes Family',
        title: 'The Cervantes Family: $75,000 to support Titan softball',
        link: 'http://news.fullerton.edu/2021/04/softball-dad-hits-it-out-of-the-park-with-gift-to-championship-program/',
        image: 'http://news.fullerton.edu/app/uploads/2021/04/Softball-Building-Render-800x500.jpg',
        imageAlt: 'Softball clubhouse rendering'
      },
      {
        date: 'March 2021',
        amount: '$650,000',
        donor: 'Northeastern Funded By Pivotal Ventures',
        title: '$650,000 grant to attract, retain and graduate women in computer science',
        link: 'http://news.fullerton.edu/2021/03/650000-grant-to-attract-retain-and-graduate-women-in-computer-science/',
        image: 'http://news.fullerton.edu/app/uploads/2021/03/women-computer-science-800x500.jpg',
        imageAlt: '3 female students looking at computer screen'
      },
      {
        date: 'March 2021',
        amount: '$450,000',
        donor: '#TitansGive day of giving',
        title: '#TitansGive day of giving: more than $450,000 raised in 24 hours',
        link: 'http://news.fullerton.edu/2021/03/universitys-day-of-giving-raises-more-than-450000/',
        image: 'http://news.fullerton.edu/app/uploads/2021/03/It-Takes-A-Titan-Campaign-Update-1-800x500.jpg',
        imageAlt: 'Graduates Celebrating with Camapign text'
      },

      {
        date: 'March 2021',
        amount: '',
        donor: 'One-year anniversary',
        title: 'One-year anniversary of campaign launch',
        link: null,
        image: anniversaryGraph,
        imageAlt: 'Pie chart showing 85% progress $200K goal'
      }
    ]

    const storylist = stories.map(function(story,index){

      var storyLink = '';
      if (story.link) {
        storyLink = <a href={story.link} className="pill orange" title={`${'Read: '}${story.title}`} target="_blank" rel="noopener noreferrer">Read More</a>
      }

      var storyImage = '';
      if (story.image) {
        storyImage = <img src={story.image} alt={story.imageAlt} className="story-image" />
      }

      return (
        <li className={`${'story story'}${index}`}>
          <span className="line" />
          <div className="story-container">
            <span className="gift-date">{story.date}</span>
            <span className="donor-name">{story.donor}</span>
            <span className="gift-amount">{story.amount}</span>
            <div className="hidden">
              {storyImage}
              <span className="title">{story.title}</span>
              {storyLink}
            </div>
          </div>
        </li>
      )

    })

    // const storylistDots = stories.map(function(story,index){

    //   return (
    //     <li className={`${'story-dot story'}${index}`}></li>
    //   )

    // })


    if (this.state.width < 1025) {
    // if (isMobile) {
      var content = <div className="mobile-container">
      <div className="content-container animated fadeIn">
          <div className="intro-content animated fadeInLeft delay-1s">
  
            <h1>{pageTitle}</h1>
            
            <span className="sub-title">{pageDesc}</span>
  
            <p><span className="intro-text">Titan resilience and generosity</span> were on full display in the first year of Cal State Fullerton’s comprehensive campaign, “It Takes a Titan.”</p> 
  
            <p>Amid a pandemic that swiftly increased student unemployment, food insecurity and even homelessness, the Titan community stepped up to help with basic needs, while continuing to support future student success in the post-pandemic world.</p>
  
            <p>The university is celebrating that “It Takes a Titan” has raised nearly $170 million and is 85% to its goal of $200 million. To learn more about the campaign and how to make a difference in the life of a Titan, visit <a href="https://campaign.fullerton.edu" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu</a>.</p>
  
            <div className="directions">
              <p>Scroll horizontally to view the timeline, tap a story to learn more. <Icon name="arrow-right" alt="Right arrow" /></p>
            </div>
  
          </div>
  
          <ul className="story-list">{storylist}</ul>
          {/* <ul className="story-list-dots">{storylistDots}</ul> */}
  
        </div>
      </div>
    } else {
      var content = <HorizontalScroll reverseScroll={true} style={{height: '100vh', minHeight: '700px'}}>
        <div className="content-container animated fadeInUp">
          <div className="intro-content animated fadeInLeft delay-1s">

            <h1>{pageTitle}</h1>
            
            <span className="sub-title">{pageDesc}</span>

            <p><span className="intro-text">Titan resilience and generosity</span> were on full display in the first year of Cal State Fullerton’s comprehensive campaign, “It Takes a Titan.”</p> 

            <p>Amid a pandemic that swiftly increased student unemployment, food insecurity and even homelessness, the Titan community stepped up to help with basic needs, while continuing to support future student success in the post-pandemic world.</p>

            <p>The university is celebrating that “It Takes a Titan” has raised nearly $170 million and is 85% to its goal of $200 million. To learn more about the campaign and how to make a difference in the life of a Titan, visit <a href="https://campaign.fullerton.edu" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu</a>.</p>

            <a href="https://campaign.fullerton.edu/" className="campaign-logo" target="_blank" rel="noopener noreferrer"><img src={campaignLogo} alt="It Takes a Titan - The Campaign for Cal State Fullerton" /></a>

            <div className="directions">
              <p>Scroll <img src={ScrollIcon} alt="" style={{width: '1.3em', verticalAlign: 'middle'}} className="inline-icon" /> to view the timeline, hover over a story to learn more.</p>
            </div>

          </div>

          <ul className="story-list">{storylist}</ul>
          {/* <ul className="story-list-dots">{storylistDots}</ul> */}

        </div>
      </HorizontalScroll>
    }


    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <TextPDF slug={slug} />


        {content}

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 